import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Column, Section } from '../components/layout';
import SectionIcon from '../components/section-icon';
import { StaticImage } from 'gatsby-plugin-image';
import SearchIcon from '../images/search.svg';

const searchImage = (
  <Box
    ml={[0, 4]}
    maxWidth={[746 / 2, 0.5, 746 / 2]}
    maxHeight={[993 / 2, 0.5, 993 / 2]}
  >
    <StaticImage src="../images/search-example.png" alt="Search Bar" />
  </Box>
);

const SearchSection: React.FC = () => (
  <Section
    height={[1100, 740]}
    background={'uncommonGradient1'}
    skewedTop
    skewedBottom
  >
    <Box
      display="flex"
      flexDirection={['column', 'row']}
      pt={[0, 12]}
      color="white"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <Column gap={4} alignItems="center" minWidth="50%" maxWidth={375}>
        <SectionIcon
          icon={<SearchIcon />}
          background={'uncommonReverseGradient1'}
        />
        <Typography component="h2" variant="h1" align="center">
          Quick search.
        </Typography>
        <Typography variant="h5" align="center">
          Smart search bar with <b>auto-suggestions</b> to help you input kana,
          radicals and all kinds of tags.
        </Typography>
        <Box display={['block', 'none']}>{searchImage}</Box>
        <Column alignItems="center">
          <Typography variant="subtitle1">
            Get the list of jōyō kanji with radical 手:
          </Typography>
          <StaticImage
            width={852 / 2}
            height={96 / 2}
            src="../images/search-query1.png"
            alt="Search Query Example 1"
          />
          <Typography variant="subtitle1">
            Find all common verbs in JLPT N3:
          </Typography>
          <StaticImage
            width={852 / 2}
            height={96 / 2}
            src="../images/search-query2.png"
            alt="Search Query Example 2"
          />
        </Column>
      </Column>
      <Box display={['none', 'block']}>{searchImage}</Box>
    </Box>
  </Section>
);

export default SearchSection;
