import * as React from 'react';
import { Column, Section } from '../components/layout';
import SectionIcon from '../components/section-icon';
import GradientText from '../components/gradient-text';
import { Box, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import DiamondIcon from '../images/diamond.svg';

const ColorsSection: React.FC = () => {
  return (
    <Section>
      <Box
        display="flex"
        flexDirection={['column', 'column', 'row']}
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Column gap={4} alignItems="center" maxWidth={[375, 375, 320]}>
          <SectionIcon icon={<DiamondIcon />} background={'commonGradient4'} />
          <GradientText variant="h1" gradient="commonGradient4" align="center">
            Color Coded.
          </GradientText>
          <Typography variant="h5" align="center">
            All kanji, readings and words are ranked and{' '}
            <b>color coded by frequency</b> of usage.
          </Typography>
          <Typography variant="h5" align="center">
            Instantly identify what is commonly used and focus on what matters.
          </Typography>
        </Column>
        <Box ml={[0, 0, 4]} maxWidth={[1, 494]} maxHeight={[1, 421]}>
          <StaticImage src="../images/color-legend.png" alt="Colors Legend" />
        </Box>
      </Box>
    </Section>
  );
};

export default ColorsSection;
