import * as React from 'react';
import { Column, Section } from '../components/layout';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-theme-material-ui';

const HeroGroup = styled('footer')`
  a {
    color: ${({ theme }) => theme.color.uncommon};
  }
  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  a:active {
    text-decoration: underline;
  }
`;

const Hero: React.FC = () => {
  const CTABox = (
    <HeroGroup>
      <Box display="flex" alignItems="center">
        <Link to="https://apps.apple.com/us/app/kanjiverse/id1556859583?itsct=apps_box_badge&amp;itscg=30200">
          <img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1657929600&h=8d118c0ae4bd722464680a5fadeafcfd"
            alt="Download on the App Store"
            width={120.6600037}
            height={41}
          />
        </Link>
        <Link to="https://play.google.com/store/apps/details?id=co.appbrew.kanjiverse&utm_source=web_user&utm_campaign=site_badge&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            height={59}
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </Link>
      </Box>
      {/*<Box pb={2}>*/}
      {/*  <Typography variant="body1">*/}
      {/*    <strong>iOS</strong> users can join the beta via{' '}*/}
      {/*    <Link*/}
      {/*      to="https://testflight.apple.com/join/0uDA1kNS"*/}
      {/*      underline="none"*/}
      {/*    >*/}
      {/*      <strong>Testflight</strong>*/}
      {/*    </Link>*/}
      {/*    .*/}
      {/*    <br />*/}
      {/*  </Typography>*/}
      {/*</Box>*/}
    </HeroGroup>
  );

  return (
    <>
      <Section
        height={[1000, 820, 560]}
        background={'uncommonGradient2'}
        skewedBottom
      >
        <Box
          pt={8}
          display="flex"
          flexDirection={['column', 'row']}
          justifyContent="space-evenly"
          color="white"
        >
          <Column pt={[2, 8, 4]} maxWidth={[1, 350, 500]}>
            <Typography variant="h1">
              Learn Kanji.
              <br />
              Build your vocabulary.
              <br />
              Read <i>real</i> Japanese.
            </Typography>
            <Box pt={2} />
            <Box maxWidth={400}>
              <Typography variant="h5">
                Japanese Learner's dictionary, reloaded.
              </Typography>
            </Box>
            <Box
              pt={[6, 8]}
              display={['block', 'none', 'block']}
              maxWidth={[1, 500]}
              alignSelf="stretch"
            >
              {CTABox}
            </Box>
          </Column>
          <Box
            ml={[0, 4, 4]}
            mt={[4, 0]}
            width={598 / 2}
            height={1104 / 2}
            alignSelf="center"
          >
            <StaticImage
              src="../images/phone-mockup-front.png"
              alt="Phone Mockup"
            />
          </Box>
        </Box>
        <Box
          display={['none', 'block', 'none']}
          pt={[0, 2, 0]}
          justifyContent="center"
          alignItems="center"
          color="white"
        >
          {CTABox}
        </Box>
      </Section>
      <Box display={['block', 'none']} pb={16} />
    </>
  );
};

export default Hero;
