import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Column, Section } from '../components/layout';
import SectionIcon from '../components/section-icon';
import { StaticImage } from 'gatsby-plugin-image';
import ReaderIcon from '../images/reader.svg';

const ReadSection: React.FC = () => (
  <>
    <Section
      height={[870, 680]}
      background={'uncommonGradient2'}
      skewedTop
      skewedBottom
    >
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        color="white"
        pt={[0, 12]}
        justifyContent="center"
        alignItems="center"
      >
        <Column gap={4} alignItems="center" maxWidth={375}>
          <SectionIcon
            icon={<ReaderIcon />}
            background={'uncommonReverseGradient2'}
          />
          <Typography component="h2" variant="h1" align="center">
            Read <i>real</i> Japanese.
          </Typography>
          <Typography variant="h5" align="center">
            Ditch your textbooks, we&apos;ve got better examples from the{' '}
            <b>real world</b> (coming soon).
            <p>
              <i>Anime, drama, jpop, news, forums...</i> Learn from what you
              care about.
            </p>
          </Typography>
        </Column>
        <Box
          display="flex"
          flexDirection="column"
          alignItems={['center', 'center', 'flex-start']}
        >
          <StaticImage
            width={744 / 2}
            height={276 / 2}
            src="../images/example-vegeta.png"
            alt="Manga Quote"
          />
          <Box ml={[0, 0, 4]}>
            <StaticImage
              width={744 / 2}
              height={276 / 2}
              src="../images/example-kiseki.png"
              alt="Jpop Lyric"
            />
          </Box>
          <Box ml={[0, 0, 8]}>
            <StaticImage
              width={744 / 2}
              height={424 / 2}
              src="../images/example-corona.png"
              alt="News Quote"
            />
          </Box>
        </Box>
      </Box>
    </Section>
    <Box display={['block', 'none']} />
  </>
);

export default ReadSection;
