import * as React from 'react';
import { ChangeEvent, FC, FormEvent, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  styled,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const emailRe =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const MyTextField = styled(TextField)`
  & .MuiInput-underline:before {
    border-bottom: none;
  }

  & .MuiInput-underline:after {
    border-bottom: none;
  }

  & .MuiInput-underline {
    border-bottom: none;
  }

  & .MuiInput-underline:hover:before {
    border-bottom: none;
  }
`;

const CTA: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (e: FormEvent) => {
    if (emailRe.test(email)) {
      setEmailError(false);
      setTimeout(() => setSubmitting(true), 500);
    } else {
      e.preventDefault();
      setEmailError(true);
    }
  };

  return (
    <form
      method="post"
      action="https://mautic.appbrew.co/form/submit?formId=1"
      id="mauticform_notifyme"
      onSubmit={handleSubmit}
    >
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        justifyContent={['stretch', undefined]}
        alignItems={[undefined, 'center']}
        borderRadius={[0, 30]}
        bgcolor={[undefined, 'white']}
        pl={[0, 2]}
        pr={[0, 1]}
        py={[0.5, 1]}
      >
        <Box
          px={[2, 0]}
          py={[0.5, 0]}
          mb={[2, 0]}
          mr={[0, 1]}
          borderRadius={[30, 0]}
          bgcolor={['white', undefined]}
          flexGrow={[undefined, 1]}
        >
          <MyTextField
            required
            fullWidth
            disabled={submitting}
            error={emailError}
            label={emailError ? 'Error' : undefined}
            variant={emailError ? 'outlined' : 'standard'}
            margin={emailError ? 'dense' : 'none'}
            type="email"
            id="mauticform_input_notifyme_email"
            name={email || submitting ? 'mauticform[email]' : 'email'}
            placeholder="name@email.com"
            value={email}
            onChange={(
              e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            ) => setEmail(e.target.value)}
          />
        </Box>
        <Button
          startIcon={
            submitting ? (
              <CircularProgress color="inherit" size={16} />
            ) : undefined
          }
          size={isMobile ? 'large' : 'large'}
          variant="contained"
          color="primary"
          type={submitting ? undefined : 'submit'}
          name="mauticform[submit]"
          id="mauticform_input_notifyme_submit"
        >
          {submitting ? 'SENDING...' : 'SUBSCRIBE'}
        </Button>
      </Box>
      <input
        type="hidden"
        name="mauticform[formId]"
        id="mauticform_notifyme_id"
        value="1"
      />
      <input
        type="hidden"
        name="mauticform[return]"
        id="mauticform_notifyme_return"
        value=""
      />
      <input
        type="hidden"
        name="mauticform[formName]"
        id="mauticform_notifyme_name"
        value="notifyme"
      />
    </form>
  );
};

export default CTA;
