import * as React from 'react';
import { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { Column, Section } from '../components/layout';
import SectionIcon from '../components/section-icon';
import GradientText from '../components/gradient-text';
import { ThemeColor } from '../gatsby-theme-material-ui-top-layout/theme';
import { StaticImage } from 'gatsby-plugin-image';
import FlaskIcon from '../images/flask.svg';
import GraphIcon from '../images/graph.svg';
import BrainIcon from '../images/brain.svg';

const Card: React.FC<{
  icon: ReactElement;
  color: keyof ThemeColor;
  header: string;
  content: string;
  image: JSX.Element;
}> = ({ icon, color, header, content, image }) => (
  <Column alignItems="center" gap={2} pt={4}>
    <SectionIcon icon={icon} background={color} />
    <GradientText variant="h3" gradient={color}>
      {header}
    </GradientText>
    <Typography variant="body1">{content}</Typography>
    {image}
  </Column>
);

const StudySection: React.FC = () => {
  return (
    <Section>
      <Column gap={4} alignItems="center">
        <Typography component="h2" variant="h1" color="primary" align="center">
          Study smarter.
        </Typography>
        <Box maxWidth={375}>
          <Typography variant="h5" align="center">
            Tired of handwriting kanji again and again and never remembering
            them? Stop cramming, there are <b>better ways</b>:
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={['column', 'row', 'row']}
          flexWrap={['nowrap', 'wrap', 'nowrap']}
          justifyContent="center"
        >
          <Box order={0}>
            <Card
              icon={<FlaskIcon />}
              color="rareGradient1"
              header="Formula"
              content="As easy as 1 + 1."
              image={
                <StaticImage
                  width={324}
                  height={88}
                  src="../images/formula-card.png"
                  alt="Formula example"
                />
              }
            />
          </Box>
          <Box order={[1, 2, 1]}>
            <Card
              icon={<GraphIcon />}
              color="uncommonGradient1"
              header="Graph"
              content="For visual learners."
              image={
                <StaticImage
                  width={247}
                  height={238}
                  src="../images/graph-card.png"
                  alt="Graph example"
                />
              }
            />
          </Box>
          <Box order={[2, 1, 2]}>
            <Card
              icon={<BrainIcon />}
              color="commonGradient1"
              header="Mnemonic"
              content="Stories to tie everything together."
              image={
                <StaticImage
                  width={321}
                  height={119}
                  src="../images/mnemonic-card.png"
                  alt="Mnemonic example"
                />
              }
            />
          </Box>
        </Box>
      </Column>
    </Section>
  );
};

export default StudySection;
