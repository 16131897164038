import * as React from 'react';
import { ReactElement } from 'react';
import { Column, Section } from '../components/layout';
import SectionIcon from '../components/section-icon';
import GradientText from '../components/gradient-text';
import { ThemeColor } from '../gatsby-theme-material-ui-top-layout/theme';
import { Box, Typography } from '@mui/material';
import ThumbIcon from '../images/thumb.svg';
import BookmarkIcon from '../images/bookmark.svg';
import CloudIcon from '../images/cloud.svg';
import ShareIcon from '../images/share.svg';

const Card: React.FC<{
  icon: ReactElement;
  color: keyof ThemeColor;
  header: string;
}> = ({ icon, color, header, children }) => (
  <Box p={2} maxWidth={280}>
    <Column alignItems="center" gap={2}>
      <SectionIcon icon={icon} background={color} />
      <GradientText variant="h3" align="center" gradient={color}>
        {header}
      </GradientText>
      <Typography variant="body1" align="center">
        {children}
      </Typography>
    </Column>
  </Box>
);

const FeaturesSection: React.FC = () => {
  return (
    <Section>
      <Column gap={4} alignItems="center">
        <Typography component="h2" variant="h1" color="primary" align="center">
          Join the community.
        </Typography>
        <Typography variant="h5" align="center" style={{ maxWidth: 375 }}>
          Sign in to access more features from one account, in the app and on
          the web.
        </Typography>
        <Box display="flex" flexWrap="wrap" justifyContent="center">
          <Card
            icon={<BookmarkIcon />}
            color="rare"
            header="Build your vocabulary"
          >
            Bookmark words and kanji to build your own lists, share them or use
            someone else’s.
          </Card>
          <Card icon={<CloudIcon />} color="epic" header="Use it everywhere">
            Your preferences and lists are <b>synced to the cloud</b>. Access
            them on other devices or browsers.
          </Card>
          <Card
            icon={<ShareIcon />}
            color="legendary"
            header="Sharing is caring"
          >
            Share your vocabulary lists (available in beta), translations,
            mnemonics and <ThumbIcon /> others’ contributions (coming soon).
          </Card>
        </Box>
        <Typography variant="h3" color="primary" align="center">
          And much more to come...
        </Typography>
      </Column>
    </Section>
  );
};

export default FeaturesSection;
