import * as React from 'react';
import { Column, SectionBackground, SectionParent } from '../components/layout';
import KanjiverseLogo from '../components/kanjiverse-logo';
import CTA from '../components/cta';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Hill from '../images/hill.svg';
import Stars from '../images/stars.svg';
import Toori from '../images/toori.svg';
import Arrow from '../images/zigzag-arrow.svg';

const Logo = styled('div')`
  transform: scale(2);
  padding-top: 8px;
`;

const LaunchingSection: React.FC = () => {
  return (
    <SectionParent height={[700, 800]}>
      <SectionBackground
        height={[700, 800]}
        background={'uncommonGradient2'}
        skewedTop
      />
      <Box
        position="absolute"
        bottom={-10}
        left={0}
        display={['none', 'block']}
      >
        <Hill />
      </Box>
      <Box
        position="absolute"
        bottom={120}
        left={100}
        display={['none', 'block']}
      >
        <Toori />
      </Box>
      <Box
        position="absolute"
        bottom={300}
        left={65}
        display={['none', 'block']}
      >
        <Stars />
      </Box>
      <Column
        position="absolute"
        pt={8}
        pl={[2, 0, 16]}
        pr={[2, 8, 0]}
        color="white"
        alignItems={['stretch', 'flex-end']}
        width={[1, 1, 600]}
      >
        <Column
          id="launch-section"
          pt={8}
          alignItems="center"
          maxWidth={[1, 350]}
        >
          <Logo>
            <KanjiverseLogo />
          </Logo>
          <Box pt={[4, 8]} />
          <Typography variant="h5" align="center">
            Subscribe to our newsletter to be informed of major releases.
          </Typography>
          <Arrow />
        </Column>
        <Box width={[1, 400]} id="launch-section2">
          <CTA />
        </Box>
      </Column>
    </SectionParent>
  );
};

export default LaunchingSection;
