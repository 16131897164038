import * as React from 'react';
import { Column, Row, Section } from '../components/layout';
import SectionIcon from '../components/section-icon';
import { Box, Chip, SvgIcon, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import CheckIcon from '../images/check.svg';
import StackIcon from '../images/stack.svg';

const ListItem: React.FC = ({ children }) => (
  <Row alignItems="center" gap={2}>
    <SvgIcon color="primary">
      <CheckIcon />
    </SvgIcon>
    <Typography variant="h5">{children}</Typography>
  </Row>
);

const DesignSection: React.FC = () => {
  return (
    <Section>
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Box
          order={[1, 0]}
          pt={[4, 0]}
          mr={[0, 2, 4]}
          maxWidth={[375, 0.5, 375]}
          maxHeight={[674, 0.5, 674]}
        >
          <StaticImage src="../images/cards-example.png" alt="Card Samples" />
        </Box>
        <Column gap={4} alignItems="center" minWidth={320 - 32} maxWidth={375}>
          <SectionIcon icon={<StackIcon />} background={'uncommonGradient1'} />
          <Typography
            component="h2"
            variant="h1"
            color="primary"
            align="center"
          >
            Sleek design,
            <br />
            easy navigation.
          </Typography>
          <Typography variant="h5" align="center">
            Customizable cards show you the content you want, with a fresh and
            uncluttered design.
          </Typography>
          <Typography variant="h5" align="center">
            Tap any <Chip label="Tag" size="small" /> to see more details.
          </Typography>
          <Column gap={1}>
            <ListItem>13,000+ Kanji</ListItem>
            <ListItem>180,000+ Definitions</ListItem>
            <ListItem>200,000+ Examples</ListItem>
          </Column>
        </Column>
      </Box>
    </Section>
  );
};

export default DesignSection;
