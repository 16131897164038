import { Box, Typography } from '@mui/material';
import * as React from 'react';
import DownArrowIcon from '../images/down-arrow.svg';

const LearnMore: React.FC = () => (
  <Box display="flex" justifyContent="center" alignItems="center">
    <DownArrowIcon color="primary" />
    <Typography variant="overline" align="center" color="primary">
      Learn more
    </Typography>
    <DownArrowIcon color="primary" />
  </Box>
);

export default LearnMore;
