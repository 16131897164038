import * as React from 'react';

import Layout, { Column } from '../components/layout';
import SEO from '../components/seo';
import Hero from '../sections/hero';
import DesignSection from '../sections/design';
import LearnMore from '../components/learn-more';
import SearchSection from '../sections/search';
import StudySection from '../sections/study';
import LevelSection from '../sections/level';
import ColorsSection from '../sections/colors';
import ReadSection from '../sections/read';
import FeaturesSection from '../sections/features';
import LaunchingSection from '../sections/launching';

const IndexPage: React.FC = () => {
  return (
    <Layout gradientHeight={[1000, 820, 560]}>
      <SEO title="Japanese Learner's Dictionary" />
      <Column gap={8}>
        <Hero />
        <LearnMore />
        <DesignSection />
        <SearchSection />
        <StudySection />
        <LevelSection />
        <ColorsSection />
        <ReadSection />
        <FeaturesSection />
        <LaunchingSection />
      </Column>
    </Layout>
  );
};

export default IndexPage;
