import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Column, Section } from '../components/layout';
import SectionIcon from '../components/section-icon';
import { StaticImage } from 'gatsby-plugin-image';
import LevelIcon from '../images/level.svg';

const LevelSection: React.FC = () => (
  <Section
    height={[880, 640]}
    background={'commonGradient2'}
    skewedTop
    skewedBottom
  >
    <Box
      display="flex"
      flexDirection={['column', 'row']}
      pt={[0, 12, 16]}
      justifyContent="space-evenly"
      alignItems="center"
      color="white"
    >
      <Column
        gap={4}
        pb={[4, 0]}
        pr={[0, 4]}
        alignItems="center"
        maxWidth={[375, 375 + 32]}
      >
        <SectionIcon
          icon={<LevelIcon />}
          background={'uncommonReverseGradient1'}
        />
        <Typography component="h2" variant="h1" align="center">
          Level up.
        </Typography>
        <Typography variant="h5" align="center">
          Need more guidance with the basics or want to dig deeper? We&apos;ve
          got you covered.
          <p>
            The interface and content <b>adapt to your level</b>. Choose one of
            the preconfigured profiles or customize your own.
          </p>
        </Typography>
      </Column>
      <StaticImage
        width={670 / 2}
        height={760 / 2}
        src="../images/levels.png"
        alt="Levels"
      />
    </Box>
  </Section>
);

export default LevelSection;
